import { TweenMax, Circ } from 'gsap/all';

// Config
const duration = 0.5;

class Menu {
  constructor() {
    this.trigger = document.querySelector('.menu-trigger');
    this.main = document.querySelector('main');
    this.nav = document.querySelector('.main-nav');
    this.body = document.body.classList;
  }

  init() {
    this.trigger.addEventListener('click', () => {
      if (!this.body.contains('js-menu-open')) {
        TweenMax.to(this.main, duration, {
          x: -(window.innerWidth - 80),
          ease: Circ.easeInOut,
        });

        TweenMax.to(this.nav, duration, {
          x: -(window.innerWidth - 80),
          ease: Circ.easeInOut,
        });

        this.body.add('js-menu-open');
      } else {
        TweenMax.to(this.main, duration, {
          x: 0,
          ease: Circ.easeInOut,
        });

        TweenMax.to(this.nav, duration, {
          x: 0,
          ease: Circ.easeInOut,
        });

        this.body.remove('js-menu-open');
      }
    });
  }
}

export default new Menu();
