import { TimelineMax } from 'gsap';

export default function Philosophy() {
  const texts = Array.from(document.querySelectorAll('.philosophy__manifesto .manifesto'));
  const tl = new TimelineMax({ paused: true, onComplete() { this.restart(); } });

  texts.forEach((text) => {
    tl.to(text, 0.5, {
      autoAlpha: 1,
    })
    tl.to(text, 0.5, {
      autoAlpha: 0,
    }, '+=4');
  });

  const video = document.querySelector('.philosophy__video video');
  video.onloadeddata = () => {
    tl.play();
  };
}
