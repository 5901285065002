import { TweenMax, Circ } from 'gsap/all';

const duration = 0.5;

export default class Grid {
  constructor() {
    this.squares = document.querySelectorAll('.grid__square');
    this.guides = document.querySelectorAll('.grid .guide');
    this.frame = document.querySelector('.grid__content');
  }

  async moveTo(absx, absy, delay = 0, textScaling) {
    const x = absx / this.frame.getBoundingClientRect().width;
    const y = absy / this.frame.getBoundingClientRect().height;

    const topLeft = new Promise((resolve) => {
      TweenMax.to(this.squares[0], duration, {
        scaleX: x,
        scaleY: y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const topLeftH2 = new Promise((resolve) => {
      const scale = textScaling === 'topleft' ? 1 : 0.5;
      TweenMax.to(this.squares[0].querySelector('h2'), duration, {
        scaleX: scale / x,
        scaleY: scale / y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const topRight = new Promise((resolve) => {
      TweenMax.to(this.squares[1], duration, {
        scaleX: 1 - x,
        scaleY: y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const topRightH2 = new Promise((resolve) => {
      const scale = textScaling === 'topright' ? 1 : 0.5;
      TweenMax.to(this.squares[1].querySelector('h2'), duration, {
        scaleX: scale / (1 - x),
        scaleY: scale / y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const bottomLeft = new Promise((resolve) => {
      TweenMax.to(this.squares[2], duration, {
        scaleX: x,
        scaleY: 1 - y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const bottomLeftH2 = new Promise((resolve) => {
      const scale = textScaling === 'bottomleft' ? 1 : 0.5;
      TweenMax.to(this.squares[2].querySelector('h2'), duration, {
        scaleX: scale / x,
        scaleY: scale / (1 - y),
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const bottomRight = new Promise((resolve) => {
      TweenMax.to(this.squares[3], duration, {
        scaleX: 1 - x,
        scaleY: 1 - y,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const bottomRightH2 = new Promise((resolve) => {
      const scale = textScaling === 'bottomright' ? 1 : 0.5;
      TweenMax.to(this.squares[3].querySelector('h2'), duration, {
        scaleX: scale / (1 - x),
        scaleY: scale / (1 - y),
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const horGuide = new Promise((resolve) => {
      TweenMax.to(this.guides[1], duration, {
        left: `${x * 100}%`,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    const verGuide = new Promise((resolve) => {
      TweenMax.to(this.guides[0], duration, {
        top: `${y * 100}%`,
        ease: Circ.easeInOut,
        onComplete: resolve,
      });
    });

    await Promise.all([
      topLeft,
      topLeftH2,
      topRight,
      topRightH2,
      bottomLeft,
      bottomLeftH2,
      bottomRight,
      bottomRightH2,
      horGuide,
      verGuide,
    ]);
    return new Promise(resolve => setTimeout(resolve, delay * 1000));
  }
}
