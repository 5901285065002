export default function commons(data) {
  // Video substitution
  if (!document.body.matches('.is-mobile')) {
    const videos = Array.from(data.next.container.querySelectorAll('video'));
    videos.forEach((video) => {
      const sourceMp4 = document.createElement('source');
      sourceMp4.setAttribute('src', video.getAttribute('data-mp4'));
      sourceMp4.setAttribute('type', 'video/mp4');
      video.appendChild(sourceMp4);
    });
  }
}
