import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'gsap';
import barba from '@barba/core';
import Menu from './components/Menu';

import commons from './base/commons';
import Philosophy from './pages/Philosophy';
import Index from './pages/Index';

const beforeAnimation = el => new Promise((resolve) => {
  TweenMax.to(document.querySelector('.curtain'), 0.5, {
    x: 0,
    ease: Circ.easeInOut,
  });
  TweenMax.to(el, 0.5, {
    autoAlpha: 0,
    ease: Circ.easeInOut,
    onComplete: resolve,
  });
});

const enterAnimation = el => new Promise((resolve) => {
  TweenMax.fromTo(el, 0.4, {
    autoAlpha: 0,
  },
  {
    autoAlpha: 1,
    ease: Circ.easeInOut,
    onComplete: resolve,
  });
});

const afterAnimation = () => new Promise((resolve) => {
  TweenMax.to(document.querySelector('.curtain'), 0.5, {
    x: window.innerWidth + 12,
    ease: Circ.easeInOut,
    onComplete: () => {
      TweenMax.set(document.querySelector('.curtain'), {
        x: -(window.innerWidth + 12),
      });
      resolve();
    },
  });
});

barba.hooks.afterEnter(commons);

barba.init({
  schema: {
    prefix: 'data-sail',
  },
  views: [{
    namespace: 'philosophy',
    afterEnter: Philosophy,
  },
  {
    namespace: 'index',
    afterEnter: Index,
  }],
  transitions: [{
    async before(data) {
      await beforeAnimation(data.current.container);
    },

    // async leave(data) {
    //   await leaveAnimation(data.current.container);
    // },

    async enter(data) {
      await enterAnimation(data.next.container);
    },

    async after() {
      await afterAnimation();
    },
  }],
});

const init = () => {
  Menu.init();
};

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  // call on next available tick
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}

// Menu.init();
