import Grid from '../components/Grid';

export default function Index(data) {
  const content = document.querySelector('.grid__content');
  console.log(data);
  
  if (true) {
    const grid = new Grid();

    const staticX = window.innerWidth < 600 ? 50 : 200;
    const staticY = window.innerWidth < 600 ? 50 : 100;

    const rightDistance = grid.frame.getBoundingClientRect().width - staticX;
    const botDistance = grid.frame.getBoundingClientRect().height - staticY;
    const halfX = grid.frame.getBoundingClientRect().width / 2;
    const halfY = grid.frame.getBoundingClientRect().height / 2;


    grid.moveTo(staticX, staticY, 1, 'bottomright')
      .then(() => grid.moveTo(rightDistance, staticY, 1, 'bottomleft'))
      .then(() => grid.moveTo(rightDistance, botDistance, 1, 'topleft'))
      .then(() => grid.moveTo(staticX, botDistance, 1, 'topright'))
      .then(() => grid.moveTo(halfX, halfY, 0))
      .then(() => { content.classList.add('ready'); });
  } else {
    content.classList.add('ready');
  }

  Array.from(document.querySelectorAll('.square')).forEach((square) => {
    const el = square.querySelector('video');
    el.pause();
    square.addEventListener('mouseenter', () => el.play());
    square.addEventListener('mouseleave', () => el.pause());
  });
}
